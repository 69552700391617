/** @jsx jsx */
import { jsx } from "theme-ui"
import BlogListItem from "./blog-list-item"
import {useCallback, useEffect} from "react";
import {withShortcut} from "react-keybind";

type ListingProps = {
  posts: {
    slug: string
    title: string
    date: string
    excerpt: string
    description: string
    timeToRead?: number
    tags?: {
      name: string
      slug: string
    }[]
  }[]
  className?: string
  showTags?: boolean
}

const Listing = ({ posts, className, showTags = true, shortcut }: ListingProps) => {
  const focus = useCallback(() => {
    // TODO: focus
  }, [])
  useEffect(() => {
    // shortcut.registerShortcut(focus, ['c'], 'Content', 'Jump to Content')
    return () => {
      // shortcut.unregisterShortcut(['c'])
    }
  }, [])
  return (
      <section sx={{ mb: [5, 6, 7] }} className={className}>
        {posts.map((post) => (
            <BlogListItem key={post.slug} post={post} showTags={showTags} />
        ))}
      </section>
  )
}

export default withShortcut(Listing);
